<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col md="12">
        <b-card class="card-congratulation-medal" v-if="user && user.technician">
          <h4>Enhorabuena 🎉 Técnico!</h4>
          <b-card-text class="font-small-3">
            ¡Has alcanzado tus objetivos!
          </b-card-text>
          <h3 class="mb-75 mt-2 pt-50">
            + 300 Servicios.
          </h3>
         
          <b-img
            :src="require('@/assets/images/illustration/badge.svg')"
            class="congratulation-medal"
            alt="Medal Pic"
          />
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card no-body class="card-statistics">
          <b-card-header>
            <b-card-title>Estadísticas</b-card-title>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-row>
              <b-col  xl="3"  sm="6" class="mt-2 mb-2 mb-xl-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary">
                      <feather-icon size="24" icon="TrendingUpIcon"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">                   
                    <h5 class="font-weight-bolder mb-0">Servicios Hechos Total: </h5>
                    <b-card-text class="font-small-4 mb-0">Esta Semana: {{ stats.done.week }}</b-card-text>
                    <b-card-text class="font-small-3 mb-0">Este Mes: {{ stats.done.month }}</b-card-text>
                    <b-card-text class="font-small-3 mb-0">Este Trimestre: {{ stats.done.quarter }}</b-card-text>
                    <b-card-text class="font-small-3 mb-0">Este año: {{ stats.done.year }}</b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col  xl="3"  sm="6" class="mt-2 mb-2 mb-xl-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-info">
                      <feather-icon size="24" icon="UserIcon"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">                   
                    <h5 class="font-weight-bolder mb-0"> Servicios Hechos Clientes Repiten: </h5>
                    <b-card-text class="font-small-4 mb-0">Esta Semana:  {{ stats.repeat.week }}</b-card-text>
                    <b-card-text class="font-small-3 mb-0">Este Mes: {{ stats.repeat.month }}</b-card-text>
                    <b-card-text class="font-small-3 mb-0">Este Trimestre: {{ stats.repeat.quarter }}</b-card-text>
                    <b-card-text class="font-small-3 mb-0">Este año: {{ stats.repeat.year }}</b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col  xl="3"  sm="6" class="mt-2 mb-2 mb-xl-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-danger">
                      <feather-icon size="24" icon="BoxIcon"/>                      
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h5 class="font-weight-bolder mb-0"> Servicios Rechazados: </h5>
                    <b-card-text class="font-small-4 mb-0">Esta Semana:  {{ stats.refused.week }}</b-card-text>
                    <b-card-text class="font-small-3 mb-0">Este Mes: {{ stats.refused.month }}</b-card-text>
                    <b-card-text class="font-small-3 mb-0">Este Trimestre: {{ stats.refused.quarter }}</b-card-text>
                    <b-card-text class="font-small-3 mb-0">Este año: {{ stats.refused.year }}</b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col  xl="3"  sm="6" class="mt-2 mb-2 mb-xl-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-success">
                      <feather-icon size="24" icon="DollarSignIcon"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h5 class="font-weight-bolder mb-0"> Servicios nulos: </h5>
                    <b-card-text class="font-small-4 mb-0">Esta Semana:  {{ stats.null.week }}</b-card-text>
                    <b-card-text class="font-small-3 mb-0">Este Mes: {{ stats.null.month }}</b-card-text>
                    <b-card-text class="font-small-3 mb-0">Este Trimestre: {{ stats.null.quarter }}</b-card-text>
                    <b-card-text class="font-small-3 mb-0">Este año: {{ stats.null.year }}</b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col  xl="3"  sm="6" class="mt-2 mb-2 mb-xl-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-danger">
                      <feather-icon size="24" icon="TrendingUpIcon"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h5 class="font-weight-bolder mb-0"> Reclamaciones: </h5>
                    <b-card-text class="font-small-4 mb-0">Esta Semana:  {{ stats.complain.week }}</b-card-text>
                    <b-card-text class="font-small-3 mb-0">Este Mes: {{ stats.complain.month }}</b-card-text>
                    <b-card-text class="font-small-3 mb-0">Este Trimestre: {{ stats.complain.quarter }}</b-card-text>
                    <b-card-text class="font-small-3 mb-0">Este año: {{ stats.complain.year }}</b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col  xl="3"  sm="6" class="mt-2 mb-2 mb-xl-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-success">
                      <feather-icon size="24" icon="DollarSignIcon"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h5 class="font-weight-bolder mb-0">Beneficio Técnico: </h5>
                    <b-card-text class="font-small-4 mb-0">Esta Semana:  {{ stats.profit.week }}€</b-card-text>
                    <b-card-text class="font-small-3 mb-0">Este Mes: {{ stats.profit.month }}€</b-card-text>
                    <b-card-text class="font-small-3 mb-0">Este Trimestre: {{ stats.profit.quarter }}€</b-card-text>
                    <b-card-text class="font-small-3 mb-0">Este año: {{ stats.profit.year }}€</b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col>
        <b-card title="Notificaciones importante">
          <b-alert  show variant="danger" >
            <div class="alert-body">
              <feather-icon  class="mr-25" icon="AlertCircleIcon"/>
              <span class="ml-25">Tienes 10 servicios pendientes de información</span>
            </div>
          </b-alert>       
                
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col>
        <b-card title="Notificaciones">       
          <b-alert  show variant="secondary" v-for="item,index in messages">
            <div class="alert-body">
              <small class="ml-25">{{ item.author }} - ({{ item.created_at ? formatTime(item.created_at) : ''}})</small><br>
              <feather-icon  class="mr-25" icon="InfoIcon"/>
              <span class="ml-50">{{ item.note }}</span>
            </div>
          </b-alert>             
        </b-card>
      </b-col>
    </b-row>

  </section>
</template>

<script>
import { BRow, BCardHeader, BCardTitle, BCardBody, BCol, BCard, BCardText, BLink, BButton, BImg,  BMedia, BMediaAside, BAvatar, BMediaBody, BAlert } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import { mapState } from 'vuex';

export default {
  components: { BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BLink, BImg, BButton, BMedia, BAvatar, BMediaAside, BMediaBody, BAlert },
  directives: {
    Ripple,
  },
  data() {
    return {
      stats : { done : [] , complain : [] , null: [], profit : [], repeat : [], refused : []  },
      messages : null
    }
  },
  created () {
    this.$http.get("/api/getStats?id=" + this.user.id).then((response) => {
      console.log(response.data);
      this.stats = response.data['stats']
      this.messages = response.data['messages']
    })   
  },
  methods: {
    kFormatter,
    formatTime(date){
      let format_date = new Date(date).toLocaleDateString('es-es',{ year: 'numeric',month: '2-digit',day: '2-digit'}) 
      let format_time = new Date(date).toLocaleTimeString('es-es',{ hour : '2-digit', minute : '2-digit'});
      return  format_date + ' - ' + format_time 
    },  
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },  
}
</script>

<style>

</style>
